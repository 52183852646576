import axios from 'axios'
import store from '../store'

let getVouchersToken = null

export default {
  methods: {
    async getVoucherById(voucherId, locationId = 0) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}`,
        {
          params: {
            locationId: locationId !== 0 ? locationId : null
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getChannelVouchers(
      channelId = 0,
      page = 0,
      itemsPerPage = 0,
      searchTerm = ''
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher`,
        {
          params: {
            channelId: channelId,
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationVouchers(
      locationId = 0,
      page = 0,
      itemsPerPage = 0,
      searchTerm = '',
      type = -1
    ) {
      if (getVouchersToken) {
        getVouchersToken.cancel()
      }
      getVouchersToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher`,
        {
          cancelToken: getVouchersToken.token,
          params: {
            locationId: locationId,
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            type: type
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async checkVoucherCodeUnique(code, locationId) {
      let criteria = {
        Code: code,
        LOcationId: locationId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/code/unique`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createVoucher(newVoucher) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher`,
        JSON.stringify(newVoucher),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucher(voucher) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucher.Id}`,
        JSON.stringify(voucher),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherPrice(voucherId, applySeatRebate, percentage, prices) {
      let criteria = {
        ApplySeatRebate: applySeatRebate,
        Percentage: percentage,
        Prices: prices
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/price/${voucherId}`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherProfile(voucherId, profileId) {
      let criteria = {
        VoucherId: voucherId,
        ProfileId: profileId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/profile/${profileId}`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteVoucherProfile(voucherId, profileId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/profile/${profileId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherLocation(voucherId, locationId) {
      let criteria = {
        VoucherId: voucherId,
        LocationId: locationId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/location/${locationId}`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteChannelVoucher(voucherId, channelId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteVoucherLocation(voucherId, locationId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async promoteVoucherLocation(voucherId, locationId) {
      let criteria = {
        VoucherId: voucherId,
        LocationId: locationId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/location/${locationId}/promote`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherCompany(voucherId, companyId) {
      let criteria = {
        VoucherId: voucherId,
        CompanyId: companyId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/company/${companyId}`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteVoucherCompany(voucherId, companyId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/company/${companyId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async checkVoucher(
      voucherCode = '',
      profileId = 0,
      locationId = 0,
      cartId = 0,
      reservationId = 0,
      options = [],
      spaces = []
    ) {
      let criteria = {
        VoucherCode: voucherCode,
        ProfileId: profileId,
        LocationId: locationId,
        CartId: cartId,
        ReservationId: reservationId,
        Options: options,
        Spaces: spaces
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/check`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationVoucherLog(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/log/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveReservationVoucherLog(reservationId, log) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/log/reservation/${reservationId}`,
        JSON.stringify(log),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async applyCartVoucher(voucherCode = '', cartKey) {
      let criteria = {
        Code: voucherCode
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/cart/${cartKey}/apply`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async revertCartVoucher(cartKey, voucherId) {
      let criteria = {
        VoucherId: voucherId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/cart/${cartKey}/revert`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async applyReservationVoucher(voucherCode = '', reservationId) {
      let criteria = {
        Code: voucherCode
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/reservation/${reservationId}/apply`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async revertReservationVoucher(reservationId, voucherId) {
      let criteria = {
        VoucherId: voucherId
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/reservation/${reservationId}/revert`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherDiscountType(criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${criteria.VoucherId}/discounttype`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveVoucherSpacePrice(voucherId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/voucher/${voucherId}/space/price`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
